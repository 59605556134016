import Rails from '@rails/ujs';

document.addEventListener("turbolinks:load", function() {

    if(document.getElementById("fecha_consulta")){
        document.getElementById("fecha_consulta").addEventListener('change',function(e){
            console.log("cambio la fecha");
            window.location.href = "citas?fecha_consulta="+document.getElementById("fecha_consulta").value;
        });
    }
    if(document.getElementById("paciente_nombre_completo")){
        document.getElementById("paciente_nombre_completo").addEventListener('keydown',function(e){e.preventDefault();});
    }
    if(document.getElementById("paciente_email") && document.getElementById("paciente_nombre_completo")){
        setConsultorio(true);
    }

    if(document.getElementById("cita_servicio_id")){
        document.getElementById("cita_servicio_id").addEventListener('change',servicio);
        muestraRef(true);
        /*med_gral();*/
        muestraHorario(true);//setConsultorio();
    }

    if(document.getElementById("cita_fecha")){
        document.getElementById("cita_fecha").addEventListener('change',setHoras);
        document.getElementById("cita_medico_id").addEventListener('change',function(){setConsultorio();});
    }

    if(document.getElementById("btnBuscarPaciente")){
        document.getElementById("btnBuscarPaciente").addEventListener('click',buscarPaciente);
        //muestraHorario();
    }

    function servicio(){
        var servicio = document.getElementById("cita_servicio_id");
        var medico = document.getElementById("cita_medico_id");
        var consultorio = document.getElementById("cita_consultorio_id");
        muestraRef();
        /*med_gral();*/ /*Para quitar el combo cuando era medicina general. Se queda como texto libre*/
        Rails.ajax({
            url: "/servicios/" + (servicio.value == '' ? 0 : servicio.value)+ "/medicos",
            type: "get",
            success: function(data) {
                for(let option of [...medico.options]){
                    option.remove();
                }
                consultorio.value='';
                document.getElementById("horarios").innerHTML="";
                //console.log(data);
                if(data.length == 0 && servicio.value != ''){
                    alert("No hay médicos asignados a ese servicio");
                }else{
                    //medico.add(new Option("",""));
                    //console.log(data);
                    //Se carga el horario_id en lugar del id del médico
                    for(let option of data){
                        medico.add(new Option(option.alias, option.id));
                    }
                    setConsultorio();
                }
            },
            error: function(data) {}
        });
    }

    function muestraRef(primeraVez=false){
        var servicio = document.getElementById("cita_servicio_id");
        var divref = document.getElementById("divref");
        var referencia = document.getElementById("cita_referencia_attributes_referencia");
        var recibo = document.getElementById("recibo");
        //var reciboTxt = document.getElementById("reciboTxt");
        var rol = document.getElementById("rol").value;
        var ref = servicio.options[servicio.selectedIndex];
        var desc = document.getElementById("desc");
        desc.innerHTML='';
        if(servicio.options[servicio.selectedIndex].dataset.desc != undefined)
            desc.innerHTML="<div style='white-space: pre-line; text-align: justify; text-justify: inter-word; font-family: mono;font-size: 1em'>" +
            servicio.options[servicio.selectedIndex].dataset.desc+"</div>";
        if(ref != undefined)
            ref = servicio.options[servicio.selectedIndex].dataset.ref;
        //Saber si ocultar o no la captura de la referencia bancaria.
        if(primeraVez==false){
            referencia.value='';
        }
        if(ref == undefined || ref == '0'){
            if(document.getElementById("paciente_email")== undefined || rol=="recepcion" || ref=="0"){  //Checar que el usuario es un paciente, xq si es doctor no se muestran los campos de referencia
                divref.style.display= 'none';
                recibo.style.display='none';
                //reciboTxt.style.display='none';
                referencia.required= false;
                referencia.value='';
            }else{
                recibo.style.display='none';
                //reciboTxt.style.display='none';
            }
        }else{
            divref.style.display='block';
            recibo.style.display='inline-block';
            //reciboTxt.style.display='block';
            referencia.required= 'required';
        }
    }

    function setConsultorio(primeraVez=false){
        var servicio = document.getElementById("cita_servicio_id");
        var medico = document.getElementById("cita_medico_id");
        var consultorio = document.getElementById("cita_consultorio_id");
        Rails.ajax({
            url: "/servicios/" + (servicio.value == '' ? 0 : servicio.value)+ "/medico/"+
            (medico.value == '' ? 0 : medico.value)+ '/consultorio',
            type: "get",
            success: function(data) {
                consultorio.value='';
                if(data == null && servicio.value != ''){
                    alert("No existe consultorio para el servicio solicitado.");
                }else if(data != null){
                    consultorio.value= data.consultorio_id;
                }
                muestraHorario(primeraVez);
            },
            error: function(data) {}
        });
    }

    function setHoras(){
        var fecha = document.getElementById("cita_fecha");
        var horas = document.getElementById("cita_inicio");
        var medico = document.getElementById("cita_medico_id");
        var servicio = document.getElementById("cita_servicio_id");
        var consul = document.getElementById("cita_consultorio_id");
        var dia = fecha.value.split("-")[2];
        var mes = fecha.value.split("-")[1];
        for(let option of [...horas.options]){
            option.remove();
        }
        
        /*if(medico.value == ""){
            fecha.value="";
            alert("Debe elegir primero al especialista");
            return;
        }*/
        
        if(fecha.value=="" || medico.value==""){
            fecha.value="";
            return;
        }
          
        //console.log("Set Horas");
        Rails.ajax({
            url: "/horarios_disponibles/"+medico.value+"/"+servicio.value+"/"+
            consul.value+"/"+fecha.value,
            type: "get",
            success: function(data) {
                if(data == null){
                    alert("El medico no tiene horarios establecidos.");
                }else if(data.length == 0){
                    alert("No hay horarios disponibles para esa fecha.");
                }else{
                    Rails.ajax({url:"/dia_inhabil/"+dia+"/"+mes+"/"+medico.value, type:"get",
                        success: function(data2){
                            if(data2.inhabil == true){
                                alert("La fecha seleccionada es día inhabil o el médico no labora, seleccione otra.");
                                fecha.value=null;
                            }else{
                                horas.add(new Option("",""));
                                for(let option of data){
                                    horas.add(new Option(option, option));
                                }
                            }
                        }
                    });
                }
            },
            error: function(data) {
                alert("Error al recuperar horarios disponibles,\ncontacte al administrador");
            }
        });
        
    }

    function muestraHorario(primeraVez=false){
        var medico = document.getElementById("cita_medico_id");
        var servicio = document.getElementById("cita_servicio_id");
        var consultorio = document.getElementById("cita_consultorio_id");
        var div = document.getElementById("horarios");
        Rails.ajax({
            url: "/horarios/"+(medico.value=='' ? 0 : medico.value)+
            '/'+(servicio.value=='' ? 0 : servicio.value)+ "/"+
            (consultorio.value=='' ? 0 : consultorio.value),
            type: "get", dataType: 'text/html',
            success: function(obj,status,data) {
                div.innerHTML=data.response;
                if(primeraVez == false)
                  setHoras();
            },
            error: function(data) {
                alert("Error al recuperar horarios disponibles,\ncontacte al administrador");
            }
        });
    }

    function buscarPaciente(){
        var email = document.getElementById("paciente_email");
        var nombre = document.getElementById("paciente_nombre_completo");
        var paciente = document.getElementById("cita_paciente_id");
        Rails.ajax({
            url: "/pacientes/buscar", data: "email="+email.value,
            type: "post", dataType: 'text/html',
            success: function(data) {
                if(data ==null){
                    nombre.value="";
                    paciente.value='';
                }else{
                    nombre.value= data.nombre + " " + data.apellidos;
                    paciente.value=data.id;
                }
            },
            error: function(data) {
                alert("Error al recuperar datos del paciente");
            }
        });
    }

    function med_gral(){
        var servicio = document.getElementById("cita_servicio_id");
        var div_motivo= document.getElementById("div_motivo");
        var div_medgral= document.getElementById("div_med_gral");
        var textarea = div_motivo.getElementsByTagName("input");
        var select = div_medgral.getElementsByTagName("select");
        if(servicio[servicio.selectedIndex].text == "Medicina General" ||
        servicio[servicio.selectedIndex].text == "Medicina General Vespertino" ||
        servicio[servicio.selectedIndex].text == "Medicina General Matutino"){
            div_motivo.style.display='none';
            textarea[0].disabled=true;
            div_medgral.style.display='block';
            select[0].disabled=false;
        }else{
            div_motivo.style.display='block';
            textarea[0].disabled=false;
            div_medgral.style.display='none';
            select[0].disabled=true;
        }
    }
});
